<div class="gst-dialog-container">
    <div class="dialog-header">
      <h2>Edit GST Details</h2>
      <button class="close-button" (click)="onCancel()">
        <span>×</span>
      </button>
    </div>

    <div class="dialog-content">
      <div class="input-group">
        <label>GST Number</label>
        <input 
          [(ngModel)]="gstNumber" 
          placeholder="Enter GST Number"
          [class.invalid]="showError"
        >
        <div class="error-message" *ngIf="showError">
          Please enter a valid GST number
        </div>
      </div>

      <div class="current-gst" *ngIf="data.currentGst">
        <span class="label">Current GST:</span>
        <span class="value">{{data.currentGst}}</span>
      </div>
    </div>

    <div class="dialog-footer">
      <button 
        class="cancel-button" 
        (click)="onCancel()"
        [disabled]="isSubmitting"
      >
        Cancel
      </button>
      <button 
        class="submit-button" 
        (click)="onSubmit()"
        [disabled]="isSubmitting || !gstNumber.trim()"
      >
        <span class="button-content">
          <span class="button-text">{{ isSubmitting ? 'Updating...' : 'Update GST' }}</span>
          <div class="spinner" *ngIf="isSubmitting"></div>
        </span>
      </button>
    </div>
  </div>