// edit-gst-dialog.component.ts
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-edit-gst-dialog',
  templateUrl: './edit-gst-dialog.component.html',
  styleUrls: ['./edit-gst-dialog.component.scss'],
})
export class EditGstDialogComponent {
  gstNumber: string = '';
  isSubmitting: boolean = false;
  showError: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<EditGstDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { storeId: number, currentGst: string },
    private http: HttpClient,
    private utils: UtilityService
  ) {
    this.gstNumber = data.currentGst || '';
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (!this.gstNumber.trim()) {
      this.showError = true;
      return;
    }

    this.showError = false;
    this.isSubmitting = true;
    
    const payload = {
      id: this.data.storeId,
      gst: this.gstNumber
    };

    this.http.put(
      `${environment.SERVER_URL}stores/updategst`,
      payload,
      {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`
        }
      }
    ).subscribe({
      next: (response: any) => {
        this.dialogRef.close(this.gstNumber);
      },
      error: (error) => {
        console.error('Error updating GST:', error);
        this.isSubmitting = false;
        this.showError = true;
      }
    });
  }
}