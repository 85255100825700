<div class="price">
  <div class="price-left">
    <app-price-sidebar (trigger)="receiveDataFromChild($event)"></app-price-sidebar>
  </div>

  <div class="price-right">
    <!-- Search Section -->
    <div class="searching-container">
      <div class="first-half-section">
        <div class="search-bar-section">
          <input
            type="text"
            class="search-user"
            placeholder="Search items..."
            [(ngModel)]="searchText"
          />
        </div>
      </div>
    </div>

    <!-- Cloths Table -->
    <section class="customer-directory-section">
      <table *ngIf="cloths && cloths.length > 0">
        <tr class="header-tr">
          <td class="header name"><b>Sr No</b></td>
          <td class="header">
            Type/Subtrade
          </td>
          <td class="header">
            <b>Item Name
              <span class="sort-icon" (click)="sortData('cloths', 'name')">
                {{getSortIcon('cloths', 'name')}}
              </span>
            </b>
          </td>
          <td class="header">
            <b>Price (₹)
              <span class="sort-icon" (click)="sortData('cloths', 'store_price')">
                {{getSortIcon('cloths', 'store_price')}}
              </span>
            </b>
          </td>
        </tr>
        <tr *ngFor="let cloth of cloths | searchPipe: searchText; let i = index">
          <td><span><b class="green-text">{{ i + 1 }}</b></span></td>
          <td>
            <div class="clothType">
              <span>{{ cloth?.category }}</span>
            </div>
          </td>
          <td><span>{{ cloth?.name }}</span></td>
          <td>{{ getDisplayPrice(cloth) }}</td>
        </tr>
        <tr *ngIf="cloths.length === 0">
          <td colspan="4" class="no-data">No Data Found.</td>
        </tr>
      </table>

      <!-- Services Table -->
      <table *ngIf="services && services.length > 0">
        <tr class="header-tr">
          <td class="header">
            Service
          </td>
          <td class="header">
            <b>Price
              <span class="sort-icon" (click)="sortData('services', 'store_price')">
                {{getSortIcon('services', 'store_price')}}
              </span>
            </b>
          </td>
        </tr>
        <tr *ngFor="let service of services | searchPipe: searchText">
          <td>
            <div class="clothType">
              <span>{{ service?.name }}</span>
            </div>
          </td>
          <td>
            {{ getDisplayPrice(service) }}
            {{ getQuantityType(service) }}
          </td>
        </tr>
        <tr *ngIf="services.length === 0">
          <td colspan="2" class="no-data">No Data Found.</td>
        </tr>
      </table>

      <!-- Addons Table -->
      <table *ngIf="addons && addons.length > 0">
        <tr class="header-tr">
          <td class="header">
            Addons
          </td>
          <td class="header">
            <b>Price
              <span class="sort-icon" (click)="sortData('addons', 'store_price')">
                {{getSortIcon('addons', 'store_price')}}
              </span>
            </b>
          </td>
        </tr>
        <tr *ngFor="let addon of addons | searchPipe: searchText">
          <td>
            <div class="clothType">
              <span>{{ addon?.name }}</span>
            </div>
          </td>
          <td>
            {{ getDisplayPrice(addon) }}
            {{ getQuantityType(addon) }}
          </td>
        </tr>
        <tr *ngIf="addons.length === 0">
          <td colspan="2" class="no-data">No Data Found.</td>
        </tr>
      </table>
    </section>
  </div>
</div>