import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ApiService } from '../services/api.service';
import { environment } from 'src/environments/environment';

export interface FilterState {
  creationDate: string;
  startDate: string;
  endDate: string;
  discountType: string;
  order_no: string;
  status: string;
  channel_type: string;
  franchise?: string;
  creationDateRange?: DateRange;
  startDateRange?: DateRange;
  endDateRange?: DateRange;
}

export interface DateRange {
  start: string | null;
  end: string | null;
}

@Component({
  selector: 'app-coupon-filter',
  templateUrl: './coupon-filter.component.html',
  styleUrls: ['./coupon-filter.component.scss']
})
export class CouponFilterComponent implements OnInit {
  @Output() filtersChanged = new EventEmitter<FilterState>();

  private filterSubject = new Subject<FilterState>();
  public filter = `${environment.assetUrl}assets/images/filter.png`;
  
  filters: FilterState = {
    creationDate: '',
    startDate: '',
    endDate: '',
    discountType: '',
    order_no: '',
    status: '',
    channel_type: ''
  };

  showDateDropdown = false;
  showFiltersDropdown = false;

  constructor(private apiService: ApiService) {
    this.filterSubject.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(filters => {
      this.emitFilters(filters);
    });
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: MouseEvent) {
    // Close dropdowns when clicking outside
    if (this.showFiltersDropdown || this.showDateDropdown) {
      this.showFiltersDropdown = false;
      this.showDateDropdown = false;
    }
  }

  ngOnInit() {
    this.emitFilters(this.filters);
  }

  updateFilter(key: keyof FilterState, event: Event) {
    const target = event.target as HTMLInputElement | HTMLSelectElement;
    this.filters = {
      ...this.filters,
      [key]: target.value
    };
    this.filterSubject.next(this.filters);
  }

  private emitFilters(filters: FilterState) {
    const activeFilters = Object.entries(filters)
      .reduce((acc, [key, value]) => {
        if (value !== null && value !== '') {
          acc[key] = value;
        }
        return acc;
      }, {} as Partial<FilterState>);

    this.filtersChanged.emit(activeFilters as FilterState);
  }

  clearFilters() {
    this.filters = {
      creationDate: '',
      startDate: '',
      endDate: '',
      discountType: '',
      order_no: '',
      status: '',
      channel_type: ''
    };
    this.emitFilters(this.filters);
    
    // Close dropdowns after clearing
    this.showFiltersDropdown = false;
    this.showDateDropdown = false;
  }

  toggleDateDropdown(event: Event) {
    event.stopPropagation();
    this.showDateDropdown = !this.showDateDropdown;
    this.showFiltersDropdown = false;
  }

  toggleFiltersDropdown(event: Event) {
    event.stopPropagation();
    this.showFiltersDropdown = !this.showFiltersDropdown;
    this.showDateDropdown = false;
  }

  ngOnDestroy() {
    this.filterSubject.complete();
  }
}