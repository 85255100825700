import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../services/api.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { FilterState } from '../coupon-filter/coupon-filter.component';
import { Subject } from 'rxjs';

interface DateRange {
  start: Date | null;
  end: Date | null;
}

interface ConsolidatedCoupon {
  title: string;
  discount_code: string;
  created_at: string;
  discount_type: number;
  flat_rate: number;
  discount: number;
  minimum_order: number;
  maximum_discount: number;
  services: string[];
  order_no: number;
  frequency: number;
  channel_type: number;
  start_date: string;
  end_date: string;
  status: number;
}

@Component({
  selector: 'app-coupons-table',
  templateUrl: './coupons-table.component.html',
  styleUrls: ['./coupons-table.component.scss'],
})
export class CouponsTableComponent implements OnInit {
  public consolidatedData: ConsolidatedCoupon[] = [];
  public selectedCoupon: ConsolidatedCoupon | null = null;
  private searchSubject = new Subject<string>();
  sortColumn: string = '';
  currentFilters: any;
  sortDirection: string = 'asc';
  activeFilter: string | null = null;
  originalData: any;
  public isLoading: boolean = true;
  
  dateRanges: { [key: string]: DateRange } = {
    creation: { start: null, end: null },
    start: { start: null, end: null },
    end: { start: null, end: null }
  };

  constructor(
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getCoupons();
  }
  
  toggleServicePopup(coupon: ConsolidatedCoupon) {
    console.log("this is coming here");
    this.selectedCoupon = coupon;
  }

  closeServicePopup() {
    this.selectedCoupon = null;
  }

   // Add new method to check if any other date filter is active
   isDateFilterActive(currentFilter: string): boolean {
    const filters = ['creation', 'start', 'end'];
    return filters
      .filter(filter => filter !== currentFilter)
      .some(filter => 
        this.dateRanges[filter].start !== null || 
        this.dateRanges[filter].end !== null
      );
  }


  // Format date for API
  public formatDateForApi(date: Date): string {
    return date.toISOString().split('T')[0];
  }

  handleFiltersChanged(filters: FilterState) {
    this.isLoading = true;
    
    // Preserve date ranges by merging them with new filters
    const updatedFilters = {
      ...filters,
      // Only include date ranges if they exist
      ...(this.dateRanges.creation.start && this.dateRanges.creation.end && {
        creationDateRange: {
          start: this.formatDateForApi(this.dateRanges.creation.start),
          end: this.formatDateForApi(this.dateRanges.creation.end)
        }
      }),
      ...(this.dateRanges.start.start && this.dateRanges.start.end && {
        startDateRange: {
          start: this.formatDateForApi(this.dateRanges.start.start),
          end: this.formatDateForApi(this.dateRanges.start.end)
        }
      }),
      ...(this.dateRanges.end.start && this.dateRanges.end.end && {
        endDateRange: {
          start: this.formatDateForApi(this.dateRanges.end.start),
          end: this.formatDateForApi(this.dateRanges.end.end)
        }
      })
    };

    this.currentFilters = updatedFilters;
    
    if (Object.keys(updatedFilters).length > 0) {
      this.getFilteredCoupons(updatedFilters);
    } else {
      this.getCoupons();
    }
  }

  onDateRangeChange(type: string, event: MatDatepickerInputEvent<Date>) {
    const value = event.value;
    const rangeType = event.targetElement.getAttribute('data-range-type');
    
    if (!this.dateRanges[type]) {
      this.dateRanges[type] = { start: null, end: null };
    }

    if (rangeType === 'start') {
      this.dateRanges[type].start = value;
    } else {
      this.dateRanges[type].end = value;
    }

    // Remove only other date filters, not all filters
    Object.keys(this.dateRanges)
      .filter(key => key !== type)
      .forEach(key => {
        this.dateRanges[key] = { start: null, end: null };
      });

    // Only apply filter if both start and end dates are set
    if (this.dateRanges[type].start && this.dateRanges[type].end) {
      // Preserve existing filters while updating date range
      const updatedFilters = { ...this.currentFilters };

      // Clear all date range filters first
      delete updatedFilters.creationDateRange;
      delete updatedFilters.startDateRange;
      delete updatedFilters.endDateRange;

      // Add only the active date filter
      switch (type) {
        case 'creation':
          updatedFilters.creationDateRange = {
            start: this.formatDateForApi(this.dateRanges.creation.start!),
            end: this.formatDateForApi(this.dateRanges.creation.end!)
          };
          break;
        case 'start':
          updatedFilters.startDateRange = {
            start: this.formatDateForApi(this.dateRanges.start.start!),
            end: this.formatDateForApi(this.dateRanges.start.end!)
          };
          break;
        case 'end':
          updatedFilters.endDateRange = {
            start: this.formatDateForApi(this.dateRanges.end.start!),
            end: this.formatDateForApi(this.dateRanges.end.end!)
          };
          break;
      }

      this.handleFiltersChanged(updatedFilters);
    }
  }

  clearDateRange(type: string) {
    this.dateRanges[type] = { start: null, end: null };
    
    // Preserve existing non-date filters while clearing the specific date range
    const updatedFilters = { ...this.currentFilters };

    switch (type) {
      case 'creation':
        delete updatedFilters.creationDateRange;
        break;
      case 'start':
        delete updatedFilters.startDateRange;
        break;
      case 'end':
        delete updatedFilters.endDateRange;
        break;
    }

    this.handleFiltersChanged(updatedFilters);
  }

  // Get filtered coupons
  private getFilteredCoupons(filters: FilterState) {
    this.isLoading = true;
    this.apiService.getFilteredCoupons(filters).subscribe({
      next: (response: any) => {
        const rawData = response?.data || [];
        this.consolidatedData = this.consolidateCoupons(rawData);
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error fetching filtered coupons:', error);
        this.isLoading = false;
        this.consolidatedData = [];
      }
    });
  }
  handleDataFetch = (response: any, isSearch: boolean = false) => {
    const rawData = Array.isArray(response) ? response : response?.data || [];
    this.consolidatedData = this.consolidateCoupons(rawData);
    this.isLoading = false;
  }

  searchCoupons = (event: any) => {
    const query = event.target.value.trim();
    if (!query) {
      this.getCoupons();
      return;
    }

    this.isLoading = true;
    this.apiService.searchCoupons(query).subscribe({
      next: (response: any) => {
        const rawData = Array.isArray(response) ? response : [];
        this.consolidatedData = this.consolidateCoupons(rawData);
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error searching coupons:', error);
        this.isLoading = false;
        this.consolidatedData = [];
      }
    });
  }
  // Get all coupons
  getCoupons() {
    this.isLoading = true;
    this.apiService.getCoupons().subscribe({
      next: (response: any) => {
        const rawData = response?.data || [];
        this.consolidatedData = this.consolidateCoupons(rawData);
        this.isLoading = false;
      },
      error: (error) => {
        console.error('Error fetching coupons:', error);
        this.isLoading = false;
        this.consolidatedData = [];
      }
    });
  }

  // Consolidate coupons data
  private consolidateCoupons(coupons: any[]): ConsolidatedCoupon[] {
    const couponMap = new Map<string, ConsolidatedCoupon>();

    coupons.forEach(coupon => {
      const existingCoupon = couponMap.get(coupon.discount_code);
      
      if (existingCoupon) {
        if (!existingCoupon.services) {
          existingCoupon.services = [];
        }
        if (coupon.name) {
          existingCoupon.services.push(coupon.name);
        }
      } else {
        couponMap.set(coupon.discount_code, {
          ...coupon,
          services: coupon.name ? [coupon.name] : []
        });
      }
    });

    return Array.from(couponMap.values());
  }

  // Helper methods for displaying data
  getDiscountType(type: number): string {
    return type === 1 ? 'Percentage' : type === 2 ? 'Flat Rate' : '-';
  }

  getStatusText(status: number): string {
    return status === 0 ? 'Cancelled' : status === 1 ? 'Valid' : 'Expired';
  }

  getStatusClass(status: number): string {
    return status === 0 ? 'cancelled' : status === 1 ? 'valid' : 'expired';
  }

  // Navigation helper
  gotoUrl(url: string) {
    this.router.navigateByUrl(url);
  }


  

  // Add this to your existing component
  sortData(column: string) {
    // If clicking the same column, toggle direction
    if (this.sortColumn === column) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      // New column, default to ascending
      this.sortColumn = column;
      this.sortDirection = 'asc';
    }

    this.consolidatedData.sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];

      // Handle dates
      if (column === 'created_at' || column === 'start_date' || column === 'end_date') {
        valueA = new Date(valueA).getTime();
        valueB = new Date(valueB).getTime();
      }

      // Handle numbers
      if (column === 'flat_rate' || column === 'discount' || 
          column === 'minimum_order' || column === 'maximum_discount' || 
          column === 'frequency') {
        valueA = Number(valueA) || 0;
        valueB = Number(valueB) || 0;
      }

      if (valueA < valueB) return this.sortDirection === 'asc' ? -1 : 1;
      if (valueA > valueB) return this.sortDirection === 'asc' ? 1 : -1;
      return 0;
    });
  }

 

  getSortIcon(column: string): string {
    if (this.sortColumn !== column) {
      return '↕'; // or use any default icon
    }
    return this.sortDirection === 'asc' ? '↑' : '↓';
  }
}