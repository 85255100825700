<div class="filters-container">
    <div class="other-filters">
      <div class="dropdown" [class.show]="showFiltersDropdown">
        <div class="filter-btn" (click)="toggleFiltersDropdown($event)" style="display: flex; justify-content: space-between;">
          <span>Filters</span>
         <div style="display: inline-block; padding: 10px;">
            <img [src]="filter" />
        </div>


        </div>
        
        <div class="dropdown-menu" [class.show]="showFiltersDropdown">
          <!-- Discount Type -->
          <div class="filter-group">
            <label class="filter-label">Discount Type</label>
            <div class="options-grid">
              <label class="radio-option">
                <input type="radio" name="discountType" value=""
                  [checked]="filters.discountType === ''"
                  (change)="updateFilter('discountType', $event)">
                <span>All</span>
              </label>
              <label class="radio-option">
                <input type="radio" name="discountType" value="1"
                  [checked]="filters.discountType === '1'"
                  (change)="updateFilter('discountType', $event)">
                <span>Percentage</span>
              </label>
              <label class="radio-option">
                <input type="radio" name="discountType" value="2"
                  [checked]="filters.discountType === '2'"
                  (change)="updateFilter('discountType', $event)">
                <span>Flat Rate</span>
              </label>
            </div>
          </div>
  
          <!-- Order Type -->
          <div class="filter-group">
            <label class="filter-label">Order Type</label>
            <div class="options-grid">
              <label class="radio-option">
                <input type="radio" name="order_no" value=""
                  [checked]="filters.order_no === ''"
                  (change)="updateFilter('order_no', $event)">
                <span>All</span>
              </label>
              <label class="radio-option">
                <input type="radio" name="order_no" value="1"
                  [checked]="filters.order_no === '1'"
                  (change)="updateFilter('order_no', $event)">
                <span>First Order</span>
              </label>
              <label class="radio-option">
                <input type="radio" name="order_no" value="2"
                  [checked]="filters.order_no === '2'"
                  (change)="updateFilter('order_no', $event)">
                <span>Any Order</span>
              </label>
            </div>
          </div>
  
          <!-- Status -->
          <div class="filter-group">
            <label class="filter-label">Status</label>
            <div class="options-grid">
              <label class="radio-option">
                <input type="radio" name="status" value=""
                  [checked]="filters.status === ''"
                  (change)="updateFilter('status', $event)">
                <span>All</span>
              </label>
              <label class="radio-option">
                <input type="radio" name="status" value="1"
                  [checked]="filters.status === '1'"
                  (change)="updateFilter('status', $event)">
                <span>Valid</span>
              </label>
              <label class="radio-option">
                <input type="radio" name="status" value="0"
                  [checked]="filters.status === '0'"
                  (change)="updateFilter('status', $event)">
                <span>Cancelled</span>
              </label>
              <label class="radio-option">
                <input type="radio" name="status" value="2"
                  [checked]="filters.status === '2'"
                  (change)="updateFilter('status', $event)">
                <span>Expired</span>
              </label>
            </div>
          </div>
  
          <!-- Channel -->
          <div class="filter-group">
            <label class="filter-label">Channel</label>
            <div class="options-grid">
              <label class="radio-option">
                <input type="radio" name="channel_type" value=""
                  [checked]="filters.channel_type === ''"
                  (change)="updateFilter('channel_type', $event)">
                <span>All</span>
              </label>
              <label class="radio-option">
                <input type="radio" name="channel_type" value="1"
                  [checked]="filters.channel_type === '1'"
                  (change)="updateFilter('channel_type', $event)">
                <span>Online</span>
              </label>
              <label class="radio-option">
                <input type="radio" name="channel_type" value="2"
                  [checked]="filters.channel_type === '2'"
                  (change)="updateFilter('channel_type', $event)">
                <span>Offline</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>