<section class="customer-directory-section">
  <div class="container gst-heading">
    <app-gst-date-component  (dateRangeSelected)="onDateRangeSelected($event)"></app-gst-date-component>
    <button class="btn primary export"(click)="exportReport()">
      <img [src]="export" class="export-icon"  alt="export-icon" /><span></span>&nbsp;Export Report
    </button>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="table-section mt-20">
          <div *ngIf="!typeOfMessage(message)">
            <app-notifications [message]="message" [type]="messageType"></app-notifications>
          </div>
          <div *ngIf="typeOfMessage(message)">
            <div *ngFor="let e of message">
              <app-notifications [message]="e" [type]="1"></app-notifications>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="gst-head">GST SUMMARY</div>
            <div class="gst-banner">
              <div class="gst-banner-card" *ngFor="let card of cards">
                <div class="icon-container">
                  <img [src]="card.iconSrc" alt="VAT Icon" class="vat-icon">
                </div>
                <div class="content">
                  <h1 class="gst-head">{{ card.title }}</h1>
                  <p class="value">₹{{ card.value }}</p>
                </div>
              </div>
            </div>
            
            <div class="table-section">
              <table>
                <thead>
                  <tr>
                    <th>S.No.</th>
                    <th>Booking ID</th>
                    <th (click)="sortData('invoice_number')" style="cursor: pointer">
                      Invoice No. {{ getSortIcon('invoice_number') }}
                    </th>
                    <th>Invoice Date</th>
                    <th>Customer Name</th>
                    <th>Customer Ph. No.</th>
                    <th>Customer GSTIN</th>
                    <th>Place of Supply</th>
                    <th>Taxable Value</th>
                    <th>CGST</th>
                    <th>SGST</th>
                    <th>Total Invoice Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let e of data; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ e.order_number }}</td>
                    <td>{{ e.invoice_number }}</td>
                    <td>{{ e.invoice_date | date:'dd-MM-yyyy' }}</td>
                    <td>{{ e.name }}</td>
                    <td>{{ e.customer_phone }}</td>
                    <td>{{ e.customer_gst }}</td>
                    <td>{{ e.city_name }}</td>
                    <td>{{ e.taxable_value | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ e.cgst | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ e.sgst | currency:'INR':'symbol':'1.2-2' }}</td>
                    <td>{{ e.final_amount | currency:'INR':'symbol':'1.2-2' }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>