import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';
// import { Chart } from 'chart.js';
import { Chart, registerables } from 'chart.js';
import { MatDialog } from '@angular/material/dialog';
import { StoreRunnerComponent } from '../store-runner/store-runner.component';
import { EditGstDialogComponent } from '../edit-gst-dialog/edit-gst-dialog.component';
Chart.register(...registerables);
@Component({
  selector: 'app-store-profile',
  templateUrl: './store-profile.component.html',
  styleUrls: ['./store-profile.component.scss'],
})
export class StoreProfileComponent implements OnInit {
  public month: number;
  public year: number;
  public currentMonthData: any;
  public chart: any;
  public currentMonthGraphData: any[] = [];
  public previousMonthGraphData: any[] = [];
  public currentMonthGraphLabels: string[] = [];
  public dailySales: any;
  public currentDate: Date = new Date(Date.now());
  public totalSales: number = 0;
  public insights: any;
  public assets = environment.assetUrl;
  public runners: any = null;
  public months: string[] = [
    '',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  public currentChart = 1;
  // @ViewChild('lineChart') private chartRef!;
  getRunners() {
    this.http
      .get(`${environment.SERVER_URL}runners?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data);
        this.runners = data.data;
      });
  }
  dailySalesCalc() {
    // this.dailySales?.map((el: any) => {
    // });
    let sum = 0;
    for (const obj in this.dailySales) {
      if (this.dailySales[obj]) {
        sum += this.dailySales[obj];
      }
    }
    this.totalSales = sum;
  }
  openEditGstDialog() {
    console.log("it is coming here");
    const dialogRef = this.dialogRef.open(EditGstDialogComponent, {
      data: {
        storeId: this.insights?.store?.id,
        currentGst: this.insights?.store?.gst
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Update the local GST value if the dialog was successful
        this.insights.store.gst = result;
      }
    });
  }
  
  getInsights() {
    this.http
      .get(`${environment.SERVER_URL}stores/getInsights?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        console.log(data.data);
        this.insights = data.data;
      });
  }
  createChart(dataSet1: any, dataset2: any = null, labels: string[]) {
    this.chart = new Chart('MyChart', {
      type: 'line', //this denotes tha type of chart

      data: {
        // values on X-Axis
        labels: labels,
        datasets: [
          {
            label: dataSet1.label,
            data: dataSet1.data,
            backgroundColor: 'rgba(0, 0, 255, 0.2)',
            borderColor: 'rgba(101, 186, 13, 1)',

            fill: false,
          },
          {
            label: dataset2?.label,
            data: dataset2?.data,
            backgroundColor: 'rgba(240, 170, 3, 0.7)',
            borderColor: 'rgba(240, 170, 3, 0.7)',

            borderDash: [10, 5],
            fill: false,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            position: 'bottom',
          },
        },
        aspectRatio: 1,
        elements: {
          line: {
            tension: 0.4, // controls the curve of the line
            borderWidth: 2,
          },
          point: {
            radius: 3, // set the radius to 0 to remove the dots from the line chart
          },
        },
      },
    });
  }
  openModal(type: number) {
    const modal = this.dialogRef.open(StoreRunnerComponent, {
      data: {
        type,
      },
    });
    modal.componentInstance.trigger.subscribe((data) => {
      this.getRunners();
    });
  }
  openEditModal(profile: any) {
    console.log(profile);
    const modal = this.dialogRef.open(StoreRunnerComponent, {
      data: {
        type: 2,
        profile,
      },
    });
    modal.componentInstance.trigger.subscribe(() => {
      this.getRunners();
    });
  }
  constructor(
    private http: HttpClient,
    private utils: UtilityService,
    private dialogRef: MatDialog
  ) {
    this.month = new Date(Date.now()).getMonth() + 1;
    this.year = new Date(Date.now()).getFullYear();
  }
  updateChart() {
    this.chart.update();
  }
  changeChart(value: number) {
    if (value === 1) {
      this.currentChart = 1;
      this.getChartData();
    } else if (value === 2) {
      this.currentChart = 2;
      this.getYearlyData();
    }
  }
  ngOnInit(): void {
    this.getChartData();
    this.getDaySales();
    this.getInsights();
    this.getRunners();
  }
  updateMonth(type: number) {
    if (type === 1) {
      if (this.month > new Date(Date.now()).getMonth()) {
        return;
      }
      this.month++;
    } else if (type === 2) {
      if (this.month < 2) {
        return;
      }
      this.month -= 1;
    }
    this.getChartData();
  }

  getChartData() {
    this.http
      .get(
        `${environment.SERVER_URL}stores/monthlygraph?franchise=${environment.FRANCHISE}&month=${this.month}&year=${this.year}`,
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        const labels = data.data.currentMonth.map((el: any) => {
          return el.date.slice(-2);
        });
        this.currentMonthGraphLabels = labels;

        const currentMonthData = data.data.currentMonth.map(
          (e: any) => e.amount
        );
        const previousMonthGraphData = data.data.previousMonth.map(
          (el: any) => el.amount
        );
        this.chart?.destroy();
        this.createChart(
          { label: 'Current Month', data: currentMonthData },
          { label: 'Previous Month', data: previousMonthGraphData },
          labels
        );
      });
  }
  getDaySales() {
    this.http
      .get(`${environment.SERVER_URL}stores/daySales?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        this.dailySales = data.data[0];
        this.dailySalesCalc();
      });
  }
  getYearlyData() {
    this.http
      .get(`${environment.SERVER_URL}stores/yearlyGraph?franchise=${environment.FRANCHISE}&year=${this.year}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        const labels = data.data.map((e: any) => this.months[e.month]);
        const values = data.data.map((e: any) => e.amount);

        this.chart?.destroy();
        this.createChart({ label: this.year, data: values }, null, labels);
      });
  }
}
