<section class="coupon-section">
  <div class="search-container">
    <div style="display: flex; justify-content: start;">
       <input
      class="search-input"
      (input)="searchCoupons($event)"
      placeholder="Search coupon with coupon code"
    />

<app-coupon-filter (filtersChanged)="handleFiltersChanged($event)"></app-coupon-filter></div>
   

    <button class="btn primary" (click)="gotoUrl('add-coupons-step-1')">
      + New Coupon
    </button>
  </div>

  <div class="table-wrapper">
    <app-loader *ngIf="isLoading"></app-loader>

    <table *ngIf="!isLoading && consolidatedData.length > 0" class="coupon-table">
      <thead>
        <tr>
          <th>Sr. #</th>
          <th>Title</th>
          <th>Coupon Code</th>
          <th class="date-header">
            <div class="header-content">
              <span>Creation Date   <span class="sort-icon" (click)="sortData('created_at')">
                {{getSortIcon('created_at')}}
              </span></span>
              <mat-form-field class="date-picker-field">
                <mat-date-range-input [rangePicker]="creationPicker" [disabled]="isDateFilterActive('creation')" style="display: none;">
                  <input matStartDate 
                         data-range-type="start"
                         [value]="dateRanges.creation.start"
                         (dateChange)="onDateRangeChange('creation', $event)">
                  <input matEndDate 
                         data-range-type="end"
                         [value]="dateRanges.creation.end"
                         (dateChange)="onDateRangeChange('creation', $event)">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="creationPicker" [disabled]="isDateFilterActive('creation')"></mat-datepicker-toggle>
                <mat-date-range-picker #creationPicker>
                  <mat-date-range-picker-actions>
                    <button mat-button color="warn" (click)="clearDateRange('creation')">Clear</button>
                    <button mat-button color="primary" matDateRangePickerApply>Apply</button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
              </mat-form-field>
            </div>
          </th>
          <th>
            Discount Type
          </th>
          <th>
            FR
            <span class="sort-icon" (click)="sortData('flat_rate')">
              {{getSortIcon('flat_rate')}}
            </span>
          </th>
          <th>%
            <span class="sort-icon" (click)="sortData('discount')">
              {{getSortIcon('discount')}}
            </span>
          </th>
          <th>Min Order
            <span class="sort-icon" (click)="sortData('minimum_order')">
              {{getSortIcon('minimum_order')}}
            </span>
          </th>
          <th>Max Discount
            <span class="sort-icon" (click)="sortData('maximum_discount')">
              {{getSortIcon('maximum_discount')}}
            </span>
          </th>
          <th>Valid On</th>
          <th>Order Type</th>
          <th>Freq. <span class="sort-icon" (click)="sortData('frequency')">
            {{getSortIcon('frequency')}}
          </span></th>
          <th>Channel</th>
          <th class="date-header">
            <div class="header-content">
              <span>Start Date <span class="sort-icon" (click)="sortData('start_date')">
                {{getSortIcon('start_date')}}
              </span></span>
              <mat-form-field class="date-picker-field">
                <mat-date-range-input [rangePicker]="startPicker" [disabled]="isDateFilterActive('start')" style="display: none;">
                  <input matStartDate 
                         data-range-type="start"
                         [value]="dateRanges.start.start"
                         (dateChange)="onDateRangeChange('start', $event)">
                  <input matEndDate 
                         data-range-type="end"
                         [value]="dateRanges.start.end"
                         (dateChange)="onDateRangeChange('start', $event)">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="startPicker" [disabled]="isDateFilterActive('start')"></mat-datepicker-toggle>
                <mat-date-range-picker #startPicker>
                  <mat-date-range-picker-actions>
                    <button mat-button color="warn" (click)="clearDateRange('start')">Clear</button>
                    <button mat-button color="primary" matDateRangePickerApply>Apply</button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
              </mat-form-field>
            </div>
          </th>
          
          <th class="date-header">
            <div class="header-content">
              <span>End Date<span class="sort-icon" (click)="sortData('end_date')">
                {{getSortIcon('end_date')}}
              </span></span>
              <mat-form-field class="date-picker-field">
                <mat-date-range-input [rangePicker]="endPicker" [disabled]="isDateFilterActive('end')" style="display: none;">
                  <input matStartDate 
                         data-range-type="start"
                         [value]="dateRanges.end.start"
                         (dateChange)="onDateRangeChange('end', $event)">
                  <input matEndDate 
                         data-range-type="end"
                         [value]="dateRanges.end.end"
                         (dateChange)="onDateRangeChange('end', $event)">
                </mat-date-range-input>
                <mat-datepicker-toggle matIconSuffix [for]="endPicker" [disabled]="isDateFilterActive('end')"></mat-datepicker-toggle>
                <mat-date-range-picker #endPicker>
                  <mat-date-range-picker-actions>
                    <button mat-button color="warn" (click)="clearDateRange('end')">Clear</button>
                    <button mat-button color="primary" matDateRangePickerApply>Apply</button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
              </mat-form-field>
            </div>
          </th>
          
          <th>Status</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let coupon of consolidatedData; let i = index">
          <td>{{i + 1}}</td>
          <td>{{coupon.title}}</td>
          <td>{{coupon.discount_code}}</td>
          <td>
              <span class="date-part">{{coupon.created_at | date:'MMM d, yyyy'}}</span>
              <span class="time-part">{{coupon.created_at | date:'h:mm a'}}</span>
          </td>
          <td>{{getDiscountType(coupon.discount_type)}}</td>
          <td>{{coupon.flat_rate}}</td>
          <td>{{coupon.discount}}</td>
          <td>{{coupon.minimum_order}}</td>
          <td>{{coupon.maximum_discount}}</td>
          <td>
            <div class="valid-services" (click)="toggleServicePopup(coupon)">
              {{coupon.services.length > 6 ? 'Valid On All Services' : 
                (coupon.services.length < 6 ? 
                  coupon.services.slice(0, 2).join(', ') + 
                  (coupon.services.length > 2 ? ' +' + (coupon.services.length - 2) + ' more' : '')
                : '')
              }}
            </div>
          </td>
          <td>{{coupon.order_no === 1 ? 'First Order' : 'Any Order'}}</td>
          <td>{{coupon.frequency}}</td>
          <td>{{coupon.channel_type === 1 ? 'Online' : 'Offline'}}</td>
          <td>{{coupon.start_date | date}}</td>
          <td>{{coupon.end_date | date}}</td>
          <td>
            <span [ngClass]="getStatusClass(coupon.status)">
              {{getStatusText(coupon.status)}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>

   
    <div class="no-data-container"*ngIf="!isLoading && consolidatedData.length === 0" >
      <div class="sad-emoji">😢</div>
      <h2 class="no-coupon-text">No Coupons Found</h2>
      <p class="suggestion-text">It looks like there are no coupons matching your current filters.</p>
    </div>
  </div>

  <!-- Services Popup -->
<div *ngIf="selectedCoupon" class="popup-overlay" (click)="closeServicePopup()">
  <div class="popup-content" (click)="$event.stopPropagation()">
    <div class="popup-header">
      <h3>Services Available for "{{selectedCoupon.discount_code}}"</h3>
      <button class="close-btn" (click)="closeServicePopup()">&times;</button>
    </div>
    <div class="popup-body">
      <ul>
        <li *ngFor="let service of selectedCoupon.services">
          {{service}}
        </li>
      </ul>
    </div>
  </div>
</div>
</section>