import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {UtilityService} from '../service/utility.service';
import { map } from 'rxjs/operators';
interface FilterState {
  creationDate: string;
  startDate: string;
  endDate: string;
  discountType: string;
  order_no: string;
  status: string;
  channel_type: string;
  franchise?: string;
  creationDateRange?: DateRange;
  startDateRange?: DateRange;
  endDateRange?: DateRange;
}
export interface DateRange {
  start: string | null;
  end: string | null;
}
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient,  private Utils: UtilityService) { }
  // private methods
  private getBaseUrl = () => {
    const url = environment.SERVER_URL;
    return url.replace('/api/v1/', '');
  }
  private initAuthHeaders = () => {
    return new HttpHeaders({
      Authorization: `Bearer ${this.Utils.getLocalStorageItem('token')}`
    });
  }
  private get<T>(url: string): Observable<T> {
    const headers = this.initAuthHeaders();
    return this.http.get<T>(`${this.getBaseUrl()}${url}`, { headers });
  }

  private post<T>(url: string, body: any): Observable<T> {
    const headers = this.initAuthHeaders();
    return this.http.post<T>(`${this.getBaseUrl()}${url}`, body, { headers });
  }
  // public methods
  searchCoupons(query: string): Observable<any[]> {
    return this.get(`/api/v1/coupons/searchCoupons?franchise=${environment.FRANCHISE}&discount_code=${query}`)
      .pipe(
        map((response: any) => response.data as any[]) 
      );
  }
  

  getCoupons(): Observable<any> {
    return this.get(`/api/v1/coupons?franchise=${environment.FRANCHISE}`);
  }
  getFilteredCoupons(filters: FilterState): Observable<any> {
    let queryString = `/api/v1/couponsquery?franchise=${environment.FRANCHISE}`;
  
    // Handle creation date range
    if (filters.creationDateRange?.start && filters.creationDateRange?.end) {
      queryString += `&created_at_start=${filters.creationDateRange.start}&created_at_end=${filters.creationDateRange.end}`;
    }
  
    // Handle start date range
    if (filters.startDateRange?.start && filters.startDateRange?.end) {
      queryString += `&start_date_from=${filters.startDateRange.start}&start_date_to=${filters.startDateRange.end}`;
    }
  
    // Handle end date range
    if (filters.endDateRange?.start && filters.endDateRange?.end) {
      queryString += `&end_date_from=${filters.endDateRange.start}&end_date_to=${filters.endDateRange.end}`;
    }
  
    // Add other filters
    if (filters.discountType) {
      queryString += `&discountType=${filters.discountType}`;
    }
  
    if (filters.order_no) {
      queryString += `&order_no=${filters.order_no}`;
    }
  
    if (filters.status) {
      queryString += `&status=${filters.status}`;
    }
  
    if (filters.channel_type) {
      queryString += `&channel_type=${filters.channel_type}`;
    }
  
    console.log('Sending request to:', this.getBaseUrl() + queryString);
      
    return this.get(queryString).pipe(
      map((response: any) => {
        console.log('API Response:', response);
        return response;
      })
    );
  }

  generateGST(fromDate: string, toDate: string): Observable<any> {
    const headers = this.initAuthHeaders();
    return this.post(
      `/api/v1/stores/generateGST?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }

  generateDSR(fromDate: string, toDate: string): Observable<any> {
    return this.post(
      `/api/v1/stores/generateDSR?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }
  generateCollectionReport(fromDate: string, toDate: string): Observable<any> {
    return this.post(
      `/api/v1/stores/generateCollectionReport?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }
  generateQuantityReport(fromDate: string, toDate: string): Observable<any> {
    return this.post(
      `/api/v1/stores/generateQuantityReport?franchise=${environment.FRANCHISE}`,
      { from_date: fromDate, to_date: toDate }
    );
  }


  submitAddress(address: any): Observable<any> {
    return this.post(`/api/v1/address?franchise=${environment.FRANCHISE}`, address);
  }
  updateBookingService(serviceId: string, serviceData: any): Observable<any> {
    return this.post(`/api/v1/booking_services/${serviceId}?franchise=${environment.FRANCHISE}`, serviceData);
  }
  getOrderthroughs(): Observable<any> {
    return this.get(`/api/v1/stores/orderthroughs?franchise=${environment.FRANCHISE}`);
  }
  updateOrderStatus(bookingId: string | number, status: number): Observable<any> {
  return this.post(`/api/v1/bookings/updateStatus/${bookingId}?franchise=${environment.FRANCHISE}`, { status });
  }
  processPayment(bookingId: string | number, payload: any): Observable<any> {
    return this.post(`/api/v1/stores/bookings/processPayment/${bookingId}?franchise=${environment.FRANCHISE}`, payload);
  }
  generateBarcode(bookingId: number): Observable<any> {
    return this.get(`/api/v1/bookings/generateBarCode/${bookingId}?franchise=${environment.FRANCHISE}`);
  }
  
}
