import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';
import { StoreResponse } from '../shared/models/StoreResponse';

interface SortState {
  column: string;
  direction: 'asc' | 'desc';
}

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
})
export class PricingComponent implements OnInit {
  public cloths: any[] = [];
  public services: any[] = [];
  public addons: any[] = [];
  public selectedService = 1;
  public insights: StoreResponse[];
  public searchText: any;
  public BASE_URL = environment.SERVER_URL;

  // Track sort state for each table
  public sortStates: Record<string, SortState> = {
    cloths: { column: '', direction: 'asc' },
    services: { column: '', direction: 'asc' },
    addons: { column: '', direction: 'asc' }
  };

  constructor(private http: HttpClient, private utils: UtilityService) {}

  ngOnInit(): void {
    this.getInsights();
  }

  receiveDataFromChild = (data: any) => {
    this.selectedService = data.service;
    this.getInsights();
  };

  getData = () => {
    const PRICING_API_URL = `${this.BASE_URL}services/${this.selectedService}/pricing?franchise=${environment.FRANCHISE}`;
    this.http
      .get(PRICING_API_URL, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        this.cloths = this.processData(data.cloths || []);
        this.services = this.processData(Array.isArray(data.service) ? data.service : [data.service]);
        this.addons = this.processData(data.addons || []);
      });
  };

  // Process incoming data to ensure consistent price fields
  private processData(items: any[]): any[] {
    return items.map(item => ({
      ...item,
      displayPrice: item.store_price || item.price || 0,
      // Convert strings to lowercase for consistent sorting
      name: item.name?.toLowerCase() || '',
      category: item.category?.toLowerCase() || ''
    }));
  }

  // Get the appropriate sort icon for a column
  getSortIcon(tableType: string, column: string): string {
    const sortState = this.sortStates[tableType];
    if (sortState.column !== column) {
      return '↕';
    }
    return sortState.direction === 'asc' ? '↑' : '↓';
  }

  // Sort data for any table
  sortData(tableType: 'cloths' | 'services' | 'addons', column: string): void {
    const sortState = this.sortStates[tableType];
    
    // Update sort direction
    if (sortState.column === column) {
      sortState.direction = sortState.direction === 'asc' ? 'desc' : 'asc';
    } else {
      sortState.column = column;
      sortState.direction = 'asc';
    }

    // Get correct data array
    const dataArray = this[tableType];
    
    // Sort the data
    const sortedData = [...dataArray].sort((a, b) => {
      let compareResult = 0;
      
      switch (column) {
        case 'store_price':
        case 'price':
          // Use normalized display price for comparison
          compareResult = a.displayPrice - b.displayPrice;
          break;
          
        case 'name':
        case 'category':
          // String comparison using normalized lowercase values
          compareResult = a[column].localeCompare(b[column]);
          break;
          
        default:
          // Fallback for any other columns
          const valA = a[column];
          const valB = b[column];
          if (typeof valA === 'string' && typeof valB === 'string') {
            compareResult = valA.toLowerCase().localeCompare(valB.toLowerCase());
          } else {
            compareResult = valA < valB ? -1 : valA > valB ? 1 : 0;
          }
      }

      // Apply sort direction
      return sortState.direction === 'asc' ? compareResult : -compareResult;
    });

    // Update the data
    this[tableType] = sortedData;
  }

  getInsights = () => {
    this.http
      .get(`${this.BASE_URL}stores/getInsights?franchise=${environment.FRANCHISE}`, {
        headers: {
          authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
        },
      })
      .subscribe((data: any) => {
        this.insights = data.data;
        this.getData();
      });
  };

  // Helper function to get display price
  getDisplayPrice(item: any): string {
    const price = item.store_price || item.price || 0;
    return `Rs. ${price}`;
  }

  // Helper function to get quantity type
  getQuantityType(item: any): string {
    if (item.quantity_based_service) {
      return item.parenttrade_id == 10 ? 'Per Item' : 'Per KG';
    }
    if (item.type) {
      return item.type == '1' ? 'Per KG' : 'Per Item';
    }
    return '';
  }
}