<div class="main-container">
  <div class="left-container">
    <div class="left-header">
      <div class="left-header-main">
        <div class="basic-information-container">
          <div class="header">
            <b>BASIC INFORMATION</b>
          </div>
          <hr />
          <div class="basic-infromation-container">
            <div class="flex-box">
              <div class="basic-information-item">
                <b>STORE CODE</b>
                <div>{{ insights?.store.storecode }}</div>
              </div>
              <br />
              <div class="basic-information-item">
                <b>MOBILE NUMBER</b>
                <div>{{ insights?.store.mobile }}</div>
              </div>
            </div>
            <br />
            <div class="basic-information-item">
              <b>STORE NAME</b>
              <div>{{ insights?.store.name }}</div>
            </div>
            <br />
            <hr />
            <div class="basic-information-item">
              <b>REG. FRANCHISE NAME</b>
              <div>{{ insights?.store.reg_franchise_name }}</div>
            </div>
            <hr />
            <div class="basic-information-item">
              <b>ADDRESS</b>
              <div>{{ insights?.store.address }}</div>
            </div>
            <hr />
            <div class="flex-box">
              <div class="basic-information-item">
                <b>LATTITUDE</b>
                <div>{{ insights?.store.latitude }}</div>
              </div>
              <div class="basic-information-item">
                <b>LONGITUTDE</b>
                <div>{{ insights?.store.longitude }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="basic-information-container">
          <div class="header">
            <b>BANK AND ADDITIONAL INFORMATION</b>
            <div (click)="openEditGstDialog()">EDIT GST</div>
          </div>
          <hr />
          <div class="basic-infromation-container">
            <div class="flex-box">
              <div class="basic-information-item">
                <b>BANK NAME</b>
                <div>
                  {{
                    insights?.store.bank_name ? insights?.store.bank_name : "-"
                  }}
                </div>
              </div>

              <div class="basic-information-item">
                <b>ACCOUNT NUMBER</b>
                <div>
                  {{
                    insights?.store.bank_account
                      ? insights?.store.bank_account
                      : "-"
                  }}
                </div>
              </div>
              <div class="basic-information-item">
                <b>IFSC</b>
                <div>
                  {{
                    insights?.store.bank_ifsc ? insights?.store.bank_ifsc : "-"
                  }}
                </div>
              </div>
            </div>
            <br />
            <div class="flex-box">
              <div class="basic-information-item">
                <b>BHIM UPI</b>
                <div>
                  {{
                    insights?.store.bhim_upi ? insights?.store.bhim_upi : "-"
                  }}
                </div>
              </div>

              <div class="basic-information-item">
                <b>GSTIN NO.</b>
                <div>{{ insights?.store.gst ? insights?.store.gst : "-" }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="left-footer">
        <br />
        <br />
        <div class="footer-flex">
          <div class="btn-wrapper" (click)="openModal(1)">
            <button class="btn-primary">Add store runner</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="table-section mt-20">
              <!-- <div
                style="display: flex; justify-content: center"
                *ngIf="runners === null"
                class="loader-container"
              >
                <app-loader></app-loader>
              </div> -->
              <table>
                <tr class="header-tr">
                  <td class="header">Runner Name</td>
                  <td class="header">Status</td>

                  <td class="header">Contact details</td>
                  <td class="header" style="width: 20%">Joining date</td>
                  <td class="header" style="width: 20%">Releaving date</td>
                  <td class="header">Actions</td>
                </tr>
                <tr *ngFor="let e of runners; let i = index">
                  <td>
                    <span
                      ><b class="green-text">{{ e?.name }}</b></span
                    >
                  </td>
                  <td>
                    <span class="txt-align-center">{{
                      e?.status === 0 ? "INACTIVE" : "ACTIVE"
                    }}</span>
                  </td>
                  <td>
                    <span class="txt-align-center">{{ e?.mobile }}</span
                    ><br />
                  </td>
                  <td style="width: 20%">
                    <span class="txt-align-center">{{
                      e?.created_at | date
                    }}</span>
                  </td>
                  <td style="width: 20%">
                    <span class="txt-align-center">-</span>
                  </td>

                  <td
                    style="
                      width: 40%;
                      display: flex;
                      justify-content: flex-start;
                      margin: 0px;
                      padding: 0px;
                    "
                  >
                    <span
                      (click)="openEditModal(e)"
                      class="edit-btn"
                      style="text-align: left; margin: 10px; padding: 0px"
                      >EDIT</span
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right-container">
      <div class="left-right-sales-container">
        <div class="sales-box">
          <div>DAILY SALES</div>
          <div class="daily-sales-box">
            <!-- <div class="daily-sales-flex"> -->
            <div class="daily-sales-item">
              LAST MONTH SALES - {{ insights?.previouMonthSales }}
            </div>
            <div class="daily-sales-item">
              RECENT ORDER - {{ insights?.recentBooking | date }}
            </div>
            <!-- </div> -->
            <div class="daily-sales-item">LAST MONTH SALES - 3</div>
          </div>
        </div>
      </div>
      <br />
      <div class="sales-main-container">
        <div class="sales-btn-container">
          <div
            [class]="{ active: currentChart === 1 }"
            (click)="changeChart(1)"
          >
            Last month sale
          </div>
          <div
            [class]="{ active: currentChart === 2 }"
            (click)="changeChart(2)"
          >
            Last year sale
          </div>
        </div>
      </div>
      <br />
      <div class="chart-container">
        <div class="chart-container-header">
          <div class="chart-container-left">LAST MONTH SALES</div>
          <div
            class="chart-container-right"
            style="display: flex; align-items: center"
          >
            <div>{{ year }} {{ months[month] }}</div>
            <div class="pointer"  (click)="updateMonth(2)">
              <mat-icon>keyboard_arrow_left</mat-icon>
            </div>
            <div class="pointer" (click)="updateMonth(1)">
              <mat-icon>keyboard_arrow_right</mat-icon>
            </div>
          </div>
        </div>
        <br />
        <div class="chart-container-total-sales" *ngIf="currentChart == 1">
          <div class="total-sales-container">
            <b>2100</b>rs
            <div>per month</div>
          </div>
          <div class="category-wise-container">
            <div class="header">
              <b>Sales On {{ currentDate | date }}</b>
              <b>{{ totalSales }}</b>
            </div>
            <br />
            <div class="category-conatiner">
              <div class="catgory-row">
                <div class="category-item">
                  <img
                    src="{{
                      assets
                    }}../../assets/images/icons/category/drycleaning.svg"
                  />
                  <div>
                    Dry cleaning -
                    {{ dailySales?.Drycleaning ? dailySales?.Drycleaning : 0 }}
                  </div>
                </div>
                <div class="category-item">
                  <img
                    src="{{
                      assets
                    }}../../assets/images/icons/category/steamIron.svg"
                  />
                  <div>
                    Steam Iron -
                    {{
                      dailySales?.Standard_Iron ? dailySales?.Standard_Iron : 0
                    }}
                  </div>
                </div>
              </div>
              <br />
              <div class="catgory-row">
                <div class="category-item">
                  <img
                    src="{{
                      assets
                    }}../../assets/images/icons/category/washAndI.svg"
                  />
                  <div>
                    Wash and Iron -
                    {{ dailySales?.Wash_N_Iron ? dailySales?.Wash_N_Iron : 0 }}
                  </div>
                </div>
                <div class="category-item">
                  <img
                    src="{{
                      assets
                    }}../../assets/images/icons/category/drycleaning.svg"
                  />
                  <div>
                    Wash & Fold -
                    {{ dailySales?.Wash_N_Fold ? dailySales?.Wash_N_Fold : 0 }}
                  </div>
                </div>
              </div>
              <br />
              <div class="catgory-row">
                <div class="category-item">
                  <img
                    src="{{ assets }}../../assets/images/icons/category/pl.svg"
                  />
                  <div>
                    Premium laundry -
                    {{
                      dailySales?.Premium_Laundry
                        ? dailySales?.Premium_Laundry
                        : 0
                    }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart" *ngIf="currentChart == 1 || currentChart == 2">
          <canvas width="400" height="600" id="MyChart">{{ chart }}</canvas>
        </div>
      </div>
    </div>
  </div>
</div>
