<div class="sidebar" [ngClass]="{ 'sidebar-closed': isSidebarClosed }">
    <div class="sidebar-heading" (click)="toggleSidebar()">
      <img [src]="sidebar" alt="Sidebar Logo" />
    </div>
    <br />
    
    <!-- Scrollable content container -->
    <div class="sidebar-content">
      <div
        class="sidebar-over"
        (click)="emitData(parenTrade?.id)"
        [ngClass]="{ active: activeTab === parenTrade?.id }"
        *ngFor="let parenTrade of parenTrades"
        [hidden]="parenTrade?.id === 2"
      >
        <img [src]="getIcon(parenTrade)" />
        <div class="trade-name" *ngIf="!isSidebarClosed">
          {{ getCustomTradeName(parenTrade) }}
        </div>
      </div>
    </div>
  
    <div class="sidebar-bottom" (click)="scrollToBottom()">
      <img [src]="downarrow" alt="Sidebar Logo" />
    </div>
  </div>