import { HttpClient } from '@angular/common/http';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../service/utility.service';

@Component({
  selector: 'app-price-sidebar',
  templateUrl: './price-sidebar.component.html',
  styleUrls: ['./price-sidebar.component.scss'],
})
export class PriceSidebarComponent implements OnInit {
  @Output() trigger = new EventEmitter<any>();

  public activeTab = 1;
  public parenTrades = [];
  public ASSET_URL = environment?.assetUrl + 'assets/';
  public BASE_URL = environment.SERVER_URL;
  isSidebarClosed = false;
  public downarrow = `${environment.assetUrl}assets/images/down.svg`;
  public sidebar = `${environment.assetUrl}assets/images/sidebar.svg`;

  // Custom trade names mapping
  private tradeNames = new Map<number, string>([
    [1, 'Dry Cleaning'],
    [3, 'Steam Press'],
    [4, 'Wash & Iron'],
    [5, 'Wash & Fold'],
    [9, 'Premium Laundry by Kg'],
    [10, 'Standard Iron']
    // ID 2 is intentionally omitted as it should remain hidden
  ]);

  constructor(
    private http: HttpClient, 
    private utils: UtilityService
  ) {}

  getCustomTradeName(parenTrade: any): string {
    return this.tradeNames.get(parenTrade.id) || '';
  }

  toggleSidebar() {
    this.isSidebarClosed = !this.isSidebarClosed;
  }

  emitData = (id: number) => {
    this.activeTab = id;
    this.trigger.emit({ service: this.activeTab });
  }

  ngOnInit(): void {
    this.getParenTrades();
  }

  getParenTrades = () => {
    const SERVICES_URL = `${this.BASE_URL}parenttrades`;
    this.http
      .get(
        SERVICES_URL,
        {
          headers: {
            authorization: `Bearer ${this.utils.getLocalStorageItem('token')}`,
          },
        }
      )
      .subscribe((data: any) => {
        // Filter out Premium Laundry (ID: 2)
        this.parenTrades = data.services.filter(service => service.id !== 2);
      });
  };

  renderIcons = (parenTrade: any) => {
    switch (parenTrade.id) {
      case 1:
        return `${environment.assetUrl}assets/images/DryCleaning.svg`;
      case 5:
        return `${environment.assetUrl}assets/images/LaundryWashIron.svg`;
      case 4:
        return `${environment.assetUrl}assets/images/LaundryWashIron.svg`;
      case 2:
        return `${environment.assetUrl}assets/images/PremiumLaundry.svg`;
      case 3:
        return `${environment.assetUrl}assets/images/SteamPress.png`;
      case 9:
        return `${environment.assetUrl}assets/images/PremiumLaundry.svg`;
      case 10:
        return `${environment.assetUrl}assets/images/standardIron.png`;
      default:
        return '';
    }
  }

  getIcon = (parenTrade: any) => {
    return this.renderIcons(parenTrade);
  }

  scrollToBottom() {
    const content = document.querySelector('.sidebar-content');
    if (content) {
      content.scrollBy({
        top: 2000,
        behavior: 'smooth'
      });
    }
  }
}